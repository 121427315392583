import React from "react";
import { Container } from "reactstrap";
import { NavMenu } from "./NavMenu.jsx";
import { SignButton } from "./SignButton.jsx";
import Footer from "./Footer.jsx";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

export const Layout = (props) => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();

  return (
    <>
      <div className="flex-container">
        <div className="container">
          <div className="header">
            <a className="header" href="/">
              <img
                className="img"
                src={require("../assets/regjeringen.png")}
                alt="Regjeringen"
              />
              <div className="text">Regjeringer siden 1814</div>
              <div className="text">Admin</div>
            </a>
			{accounts[0] && <p className="signedInInfo">Innlogget: {accounts[0]?.name}</p>}
			<SignButton className="btn-secontary" isAuthenticated={isAuthenticated} />
          </div>
        </div>
      </div>
      <div>
        <NavMenu />
        <Container>{props.children}</Container>
        <Footer />
      </div>
    </>
  );
};
