import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import React, { useEffect, useState } from "react";

export default function Paginate({ page, setPage, size, totalElements }) {
  const totalPages = Math.ceil(totalElements / size);

  const [currentPage, setCurrentPage] = useState(0);
  const list = [...Array(totalPages).keys()];
  const [visiblelist, setVisiblelist] = useState(list.slice(0, 9));

  useEffect(() => {
    visibleList(currentPage);
  }, [currentPage, totalElements]);

  useEffect(() => {
    setCurrentPage(page-1)
  }, [page]);

  function handleClick(i, e) {
    e.preventDefault();
    setCurrentPage(i);
    setPage(i + 1);
  }
  const visibleList = (currentPage) => {
    if (totalPages < 10) {
      setVisiblelist(list);
    } else if (currentPage < 6) {
      setVisiblelist(list.slice(0, 9));
    } else if (currentPage >= 6 && totalPages - currentPage > 5) {
      setVisiblelist(list.slice(currentPage - 5, currentPage + 5));
    } else {
      setVisiblelist(list.slice(totalPages - 10, totalPages));
    }
  };

  return (
    <div>
      <Pagination aria-label="Page navigation example" className="pagination">
        <PaginationItem disabled={currentPage <= 0}>
          <PaginationLink
            onClick={(e) => handleClick(currentPage - 1, e)}
            previous
            href="#"
          />
        </PaginationItem>
        {visiblelist.map((page, i) => (
          <PaginationItem active={page === currentPage} key={page}>
            <PaginationLink onClick={(e) => handleClick(page, e)} key={page}>
              {page + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={currentPage >= totalPages - 1}>
          <PaginationLink
            onClick={(e) => handleClick(currentPage + 1, e)}
            next
            href="#"
          />
        </PaginationItem>
      </Pagination>
    </div>
  );
}
