import React, { useEffect, useState } from "react";
import { deleteDataAuth } from "../functionality/FetchApiData";
import DeleteWarning from "./DeleteWarning";
import { PositionModal } from "./person/postitionForms/PositionForm";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import "./style.css";
import dateHandler from "../functionality/TextHandler";
export default function RoleView({ role, type, options }) {
  //Type 1=statsrad, type 2=statssekretær, type 3= politisk radgiver
  const [endpoint, setEndpoint] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [del, setDel] = useState();

  useEffect(() => {
    if (type === "1") {
      setEndpoint("ministers");
    } else if (type === "2") {
      setEndpoint("state-secretaries");
    } else if (type === "3") {
      setEndpoint("political-advisors");
    }
  }, []);


  const onDelete = async () => {
    const response = await deleteDataAuth(endpoint, role.id);
	
    setDel(response);
    if (response.succeeded) {
      setOpenSnack(true);
      setTimeout(() => {
        setOpenSnack(false);
        refreshPage();
      }, 2000);
    } else {
      setTimeout(() => {
        setOpenSnack(false);
      }, 2000);
    }
  };
  function refreshPage() {
    window.location.reload();
  }
  return (
    <div>
      {type === "1" && (
        <div className="role-box">
          <div className="role-info">
            <div className="role-header">
              <h3>{role?.statsradTitle ? role?.statsradTitle : "Statsråd" } ({role?.statsradType?.type?.toLowerCase()}){role?.partiCode && `(${role.partiCode.toUpperCase()})`}</h3>
            </div>
            <div className="role-description">
              {(role?.departement?.departementName ?? "") +
                " - " +
                role?.regjering?.regjeringName}
            </div>
            <div className="date-text">
              {dateHandler(role?.fromDateTime) +
                " - " +
                (role?.toDateTime ? dateHandler(role?.toDateTime) : "Aktiv")}
            </div>
            {role.note && (
              <div className="role-avvik">
                <div className="text-red">Avviksmerknad </div>
                <div> {":  " + role.note}</div>
              </div>
            )}
          </div>
          <div className="role-btn-group">
            <PositionModal type={type} data={role} options={options} />
            <DeleteWarning onDelete={onDelete} name="rolle" />
          </div>
        </div>
      )}
      {type === "2" && (
        <div className="role-box">
          <div className="role-info">
            <div className="role-header">
              <h3>Statssekretær ({role?.statssekreterType?.type?.toLowerCase()}){role?.partiCode && `(${role.partiCode.toUpperCase()})`}</h3>
            </div>
            <div className="role-description">
              {"For statsråd " +
                role?.statsrad.person.firstName +
                " " +
                role?.statsrad.person.lastName}
            </div>
            <div className="role-description">
              {role.regjering.regjeringName}
            </div>
            <div className="date-text">
              {dateHandler(role?.fromDateTime) +
                " - " +
                (role?.toDateTime ? dateHandler(role?.toDateTime) : "Aktiv")}
            </div>
            {role.note && (
              <div className="role-avvik">
                <div className="text-red">Avviksmerknad </div>
                <div> {":  " + role.note}</div>
              </div>
            )}
          </div>
          <div className="role-btn-group">
            <PositionModal type={type} data={role} options={options} />
            <DeleteWarning onDelete={onDelete} name="rolle" />
          </div>
        </div>
      )}
      {type === "3" && (
        <div className="role-box">
          <div className="role-info">
            <div className="role-header">
              <h3>Politisk rådgiver ({role?.politiskRadgiverType?.type?.toLowerCase()}){role?.partiCode && `(${role.partiCode.toUpperCase()})`}</h3>
            </div>
            <div className="role-description">
              {"For statsråd " +
                role?.statsrad.person.firstName +
                " " +
                role?.statsrad.person.lastName}
            </div>
            <div className="role-description">
              {role.regjering.regjeringName}
            </div>
            <div className="date-text">
              {dateHandler(role?.fromDateTime) +
                " - " +
                (role?.toDateTime ? dateHandler(role?.toDateTime) : "Aktiv")}
            </div>
            {role.note && (
              <div className="role-avvik">
                <p className="text-red">Avviksmerknad </p>
                <p> {":  " + role.note}</p>
              </div>
            )}
          </div>
          <div className="role-btn-group">
            <PositionModal type={type} data={role} options={options} />
            <DeleteWarning onDelete={onDelete} name="rolle" />
          </div>
        </div>
      )}
      {del && (
        <Snackbar open={openSnack} autoHideDuration={4000}>
          <Alert severity={del.succeeded ? "success" : "error"}>
            {del.succeeded
              ? "Rollen ble slettet"
              : "Rollen ble ikke slettet, prøv igjen"}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
