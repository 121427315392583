import React from "react";
import "./style.css";

export default function InfoLine({ header, info, vertical, alert }) {
  return (
    <div className={!vertical ? "overview-infoline" : "overview-infoline-v"}>
      <div className={alert ? "overview-tag-alert" : "overview-tag"}>
        {header}
      </div>
      <div>{info}</div>
    </div>
  );
}
