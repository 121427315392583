import { Dialog, DialogContent } from "@material-ui/core";
import React, { useState } from "react";
import { Button } from "reactstrap";
import "./style.css";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

export default function DeleteWarning({ onDelete, name, prop }) {
  const [open, setOpen] = useState(false);
  const handleOpenStatus = () => {
    setOpen(!open);
  };
  function deleteEntity() {
    onDelete();
    handleOpenStatus();
  }

  return (
    <div>
      <Button className="delete-button" onClick={handleOpenStatus}>
        {"Slett " + name}
      </Button>
      <Dialog
        open={open}
        onClose={handleOpenStatus}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <div className="warning-box">
          <DeleteForeverIcon
            style={{ fontSize: 100, color: "#cd4658" }}
          ></DeleteForeverIcon>
          <DialogContent>
            <div className="warning">
              <div>Er du sikker på at du vil slette dette elementet?</div>
              <div>Det vil ikke være mulig å angre</div>

              <div className="form-actions-delete">
                <Button className="slett-btn" onClick={() => deleteEntity()}>
                  Slett
                </Button>
                <Button
                  id="avbryt"
                  onClick={(e) => handleOpenStatus()}
                  className="avbryt-button"
                >
                  Avbryt
                </Button>
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
