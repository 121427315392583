import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import PersonForm from "./PersonForm";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Link, useRouteMatch } from "react-router-dom";

export default function PersonListItem({ data, options }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const handleOpenStatus = () => {
    setOpen(!open);
  };
  const refresh = () => {
    history.push(`regjeringspolitiker/${data.id}`);
  };
  return (
    <div className="header-listitem">
      <ListItem>
        <div className="listitem-link">
          <ListItemText
            primary={
              <Link to={`regjeringspolitiker/${data.id}`} className="list-header">
                {data.lastName + ", " + data.firstName}
              </Link>
            }
            secondary={data.birthYear}
          />
        </div>
        {options && (
          <ListItemIcon>
            <IconButton onClick={handleOpenStatus}>
              <EditIcon className="icon-style" />
            </IconButton>
            <PersonForm
              open={open}
              handleOpenStatus={handleOpenStatus}
              data={data}
              options={options}
              refresh={refresh}
            />
          </ListItemIcon>
        )}
      </ListItem>
      <Divider />
    </div>
  );
}
