import { fetchAuthData } from "../apim";


export async function fetchData(endpoint) {
  try {
    const res = await fetchAuthData(`${endpoint}`);
    const data = await res.data;
    return data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}
export async function fetchDataElementAuth(endpoint, id) {
  try {
    const res = await fetchAuthData(`${endpoint}/${id}`);
    const data = await res.data;
    return data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function fetchQueryAuth(endpoint, query, param) {
  try {
    const res = await fetchAuthData(`${endpoint}?${query}=${param}`);
    const data = await res.data;
    return data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}
export async function fetchDoubleQueryAuth(endpoint, query) {
  try {
    const res = await fetchAuthData(`${endpoint}?${query}`);
    return res;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}
export async function postDataAuth(endpoint, data) {
  try {
    const res = await fetchAuthData(`${endpoint}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return res;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function editDataAuth(endpoint, id, data) {
  try {
    const res = await fetchAuthData(`${endpoint}/${id}`, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return res;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}
export async function putDataAuth(endpoint, data) {
  try {
	  console.log(`json`, JSON.stringify(data))
    const res = await fetchAuthData(`${endpoint}`, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return res;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

export async function deleteDataAuth(endpoint, id) {
  try {
    const res = await fetchAuthData(`${endpoint}/${id}`, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    return false;
  }
}
