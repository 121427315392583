import React, { useState } from "react";
import "../../style.css";
import Select from "react-select";
import "date-fns";
import { TextField } from "@material-ui/core";
import Button from "reactstrap/lib/Button";
import { editDataAuth, postDataAuth } from "../../../functionality/FetchApiData";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { validateStatsradFields } from "../../../functionality/ValidateFormFields";
import { futureDate } from "../../../functionality/TextHandler";
import { DatePicker } from "../../DatePicker";

export default function StatsradForm({ personId, data, options, setOpen }) {
  const [role, setRole] = useState({});
  const [isNewRole, setIsNewRole] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [res, setRes] = useState();
  const [alerts, setAlerts] = useState(false);

  if (data && options && !role.fromDateTime) {
    setRole(data);
    setIsNewRole(false);
  }

  const selectDefault = (optionList, personVariable) => {
    if (personVariable) {
      return optionList.findIndex((option) => option.value === personVariable);
    } else return null;
  };

  function refreshPage() {
    window.location.reload();
  }

  const onSubmit = async () => {
    if (validateStatsradFields(role, setAlerts)) {
      return;
    } else {
      if (isNewRole) {
        const result = await postDataAuth("ministers", {
          ...role,
          ["personId"]: personId,
        });
        setRes(result);
        if (result.succeeded) {
          refreshPage();
        } else {
          setOpenSnack(true);
          setTimeout(() => {
            setOpenSnack(false);
            setOpen(false);
          }, 3000);
        }
      } else {
        const result = await editDataAuth("ministers", role.id, {
          ...role,
          ["personId"]: data.personId,
        });
        setRes(result);
        if (result.succeeded) {
          refreshPage();
        } else {
          setOpenSnack(true);
          setTimeout(() => {
            setOpenSnack(false);
            setOpen(false);
          }, 4000);
        }
      }
    }
  };
  const handleChange = (variable, newValue) => {
    setRole({ ...role, [variable]: newValue });
  };

  return (
    <div>
      {options && (
        <div className="form">
          <div className="form-text-alert">
            {alerts
              ? "Fyll ut alle påkrevde felter for å opprette rollen"
              : null}
          </div>
          <div
            className={
              !role.regjeringId && alerts ? "form-text-alert" : "form-text"
            }
          >
            Regjering *
          </div>
          <Select
            className="med-text-field"
            defaultValue={
              options.regjering[
                selectDefault(options.regjering, role?.regjeringId)
              ]
            }
            options={options.regjering}
            onChange={(v) => handleChange("regjeringId", v.value)}
          />
          <div
            className={
              !role.departementId && alerts ? "form-text-alert" : "form-text"
            }
          >
            Departement *
          </div>
          <Select
            className="med-text-field"
            defaultValue={
              options.departement[
                selectDefault(options.departement, role?.departementId)
              ]
            }
            options={options.departement}
            onChange={(v) => handleChange("departementId", v.value)}
          />
          <div
            className={
              !role.statsradTypeId && alerts ? "form-text-alert" : "form-text"
            }
          >
            Statsråds type *
          </div>
          <Select
            className="small-text-field"
            defaultValue={
              options.statsradType[
                selectDefault(options.statsradType, role?.statsradTypeId)
              ]
            }
            options={options.statsradType}
            onChange={(v) => handleChange("statsradTypeId", v.value)}
          />
          <div className="form-text">Statsrådtittel</div>
          <TextField
            className="small-text-field"
            id="tittel"
            value={role.statsradTitle ?? ""}
            variant="outlined"
            onChange={(e) => handleChange("statsradTitle", e.target.value)}
          />
          <div className="form-text">Statsrådtittel på engelsk</div>
          <TextField
            className="small-text-field"
            id="enTittel"
            value={role.statsradTitleInEnglish ?? ""}
            variant="outlined"
            onChange={(e) =>
              handleChange("statsradTitleInEnglish", e.target.value)
            }
          />
          <div className="form-text">Ansvarsområde</div>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            value={role.ansvarsomrade ?? ""}
            onChange={(e) => handleChange("ansvarsomrade", e.target.value)}
          />
          <div className="form-text">Ansvarsområde på engelsk</div>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            value={role.ansvarsomradeInEnglish ?? ""}
            onChange={(e) =>
              handleChange("ansvarsomradeInEnglish", e.target.value)
            }
          />
            <div
              className={
                !role.fromDateTime && alerts ? "form-date-alert" : "form-date"
              }
            >
              Fra dato *
            </div>
			<DatePicker value={role.fromDateTime ?? null} onChange={(v) => handleChange("fromDateTime", v)}/>
            <div className="form-date">Til dato</div>
            <DatePicker value={futureDate(role.toDateTime)} onChange={(v) => handleChange("toDateTime", v) } minDate={role.fromDateTime}/>          
          <div className="form-text">Avviksmerknad</div>
          <TextField
            multiline
            rows={3}
            variant="outlined"
            value={role?.note || ""}
            onChange={(e) => handleChange("note", e.target.value)}
          />
          <div>
            <Button
              id="lagre"
              className="submit-button"
              onClick={onSubmit}
              value="Submit"
            >
              Lagre
            </Button>
            <Button
              id="avbryt"
              className="avbryt-button"
              onClick={() => setOpen(false)}
            >
              Avbryt
            </Button>
          </div>
        </div>
      )}
      {res && !res.succeeded && (
        <Snackbar open={openSnack} autoHideDuration={4000}>
          <Alert severity={"error"}>
            {isNewRole
              ? "Rollen ble ikke opprettet"
              : "Endringende dine ble ikke lagret, prøv igjen"}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
