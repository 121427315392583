import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Link, useRouteMatch } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";

export default function ListElement({
  name,
  EditComponent,
  data,
  options,
  refresh,
}) {
  const { url } = useRouteMatch();

  const [open, setOpen] = useState(false);
  const handleOpenStatus = () => {
    setOpen(!open);
  };

  return (
    <div className="listitem">
      <ListItem className="listitem">
        <div className="listitem-link">
          <ListItemText
            primary={
              <Link to={`${url}/${data.id}`} className="list-header">
                {name}{" "}
              </Link>
            }
            secondary={
              data?.toDateTime && data?.toDateTime?.substring(0, 4) !== "9999"
                ? data?.fromDateTime?.substring(0, 4) +
                  " - " +
                  data?.toDateTime?.substring(0, 4)
                : data?.fromDateTime?.substring(0, 5)
            }
          ></ListItemText>
        </div>
        <IconButton onClick={handleOpenStatus}>
          <EditIcon className="icon-style" />
        </IconButton>
        {options ? (
          <ListItemIcon>
            <EditComponent
              data={data}
              options={options}
              open={open}
              handleOpenStatus={handleOpenStatus}
            />
          </ListItemIcon>
        ) : (
          <ListItemIcon>
            <EditComponent
              data={data}
              handleOpenStatus={handleOpenStatus}
              open={open}
            />
          </ListItemIcon>
        )}
      </ListItem>
      <Divider />
    </div>
  );
}
