import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import PartyForm from "./PartyForm";
import ListElement from "../ListElement";
import Loading from "../Loading";
import { fetchDoubleQueryAuth } from "../../functionality/FetchApiData";
import Paginate from "../Pagination";
import { Divider } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Button } from "reactstrap";

export function PartyPage() {
  const [search, setSearch] = useState("");
  const [partier, setPartier] = useState();
  const [page, setPage] = useState(1);
  const [pSize, setPSize] = useState();
  const [totElements, setTotElements] = useState();
  const [open, setOpen] = useState(false);
  const handleOpenStatus = () => {
    setOpen(!open);
  };
  useEffect(() => {
    searchParti();
  }, [search, page]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  async function searchParti() {
    let query = `pageNumber=${page}&pageSize=15&`;
    if (search) {
      query += `name=${search}&`;
    }
    const { data, pageSize, totalRecords } = await fetchDoubleQueryAuth(
      "political-parties",
      query
    );
    setPartier(data);
    setPSize(pageSize);
    setTotElements(totalRecords);
  }
  function refreshPage() {
    window.location.reload();
  }

  if (partier) {
    return (
        <div className="departement">
          <h1>Partier</h1>
          <PartyForm
            data=""
            refresh={refreshPage}
            open={open}
            handleOpenStatus={handleOpenStatus}
          />
          <Button id="lagre" className="form-button" onClick={handleOpenStatus}>
            Nytt parti
            <AddCircleIcon className="button-icon" />
          </Button>
          <TextField
            type="search"
            value={search}
            placeholder="Søk etter et parti"
            inputProps={{ style: { fontSize: 20 } }}
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
          />
          <br />
          <div className="search-info">
            {"Viser " +
              ((page - 1) * pSize + 1) +
              "-" +
              Math.min(page * pSize, totElements) +
              " av " +
              totElements +
              " treff."}
          </div>
          <Divider />
          <div className="list-show">
            {partier.map((part) => (
              <ListElement
                name={part.partiName}
                date={part.fromDateTime}
                EditComponent={PartyForm}
                data={part}
                key={part.id}
                refresh={refreshPage}
              />
            ))}
          </div>
          <Paginate
            setPage={setPage}
            page={page ?? 1}
            size={pSize ?? 1}
            totalElements={totElements ?? 1}
          />
        </div>
    );
  } else {
    return <Loading />;
  }
}
