import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import "date-fns";
import { Button } from "reactstrap";
import "../style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { editDataAuth, postDataAuth } from "../../functionality/FetchApiData";
import { useHistory } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { validateDepartmentFields } from "../../functionality/ValidateFormFields";
import { futureDate } from "../../functionality/TextHandler";
import { DatePicker } from "../DatePicker";

export default function MinistryForm({
  data,
  open,
  refresh,
  handleOpenStatus,
}) {
  const [ministery, setMinistery] = useState({});
  const [isNewDep, setIsNewDep] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [res, setRes] = useState();
  const history = useHistory();
  const [alerts, setAlerts] = useState(false);

	React.useEffect(()=>{
		if (data && !ministery.departementName) {
			setMinistery(data);
			setIsNewDep(false);
		}
	},[])

	const handleOpen = () => {
		handleOpenStatus()
		setMinistery(data ? data : {});
		setIsNewDep(data?.regjeringName ? true : false);
		setAlerts(false);
	  }

  const handleChange = (variable, newValue) => {
    setMinistery({ ...ministery, [variable]: newValue });
  };

  async function onSubmit() {
    if (validateDepartmentFields(ministery, setAlerts)) {
      return;
    } else {
      if (isNewDep) {
        const response = await postDataAuth("departments", ministery);
        setRes(response);
        if (response.succeeded) {
          history.push(`departement/${response.data.id}`);
        } else {
          setOpenSnack(true);
          setTimeout(() => {
            setOpenSnack(false);
            handleOpen();
          }, 4000);
        }
      } else {
        const response = await editDataAuth("departments", ministery.id, ministery);
        setRes(response);
        if (response.succeeded) {
          if (refresh) {
            refresh();
          } else {
            history.replace(`departement/${ministery.id}`);
          }
        } else {
          setOpenSnack(true);
          setTimeout(() => {
            setOpenSnack(false);
            handleOpen();
          }, 4000);
        }
      }
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleOpen}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title" className="dialog-title-form">
          <div className="dialog-title">
            {isNewDep ? (
              <h3>Nytt departement </h3>
            ) : (
              <h3>Rediger departement</h3>
            )}
            <IconButton className="close-icon" onClick={handleOpen}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form>
          <DialogContent>
            <div className="form">
              <div className="form-text-alert">
                {alerts
                  ? "Fyll ut alle påkrevde felter for å opprette personen"
                  : null}
              </div>
              <div
                className={
                  !ministery.departementCode && alerts
                    ? "form-text-alert"
                    : "form-text"
                }
              >
                Departementskode *
              </div>
              <TextField
                className="small-text-field"
                id="standard-basic"
                variant="outlined"
                value={ministery.departementCode ?? ""}
                onChange={(e) =>
                  handleChange("departementCode", e.target.value)
                }
              />
              <div
                className={
                  !ministery.departementName && alerts
                    ? "form-text-alert"
                    : "form-text"
                }
              >
                Departementsnavn *
              </div>
              <TextField
                id="name"
                className="med-text-field"
                value={ministery.departementName ?? ""}
                variant="outlined"
                onChange={(e) =>
                  handleChange("departementName", e.target.value)
                }
              />
              <div
                className={
                  !ministery.departementNameInEnglish && alerts
                    ? "form-text-alert"
                    : "form-text"
                }
              >
                Departementsnavn på engelsk *
              </div>
              <TextField
                className="med-text-field"
                id="enDepName"
                value={ministery.departementNameInEnglish ?? ""}
                variant="outlined"
                onChange={(e) =>
                  handleChange("departementNameInEnglish", e.target.value)
                }
              />
              <div
                className={
                  !ministery.fromDateTime && alerts
                    ? "form-date-alert"
                    : "form-date"
                }
              >
                Fra dato *
              </div>
			  <DatePicker value={ministery.fromDateTime ?? null} onChange={(v) => handleChange("fromDateTime", v)}/>
              <div className="form-date">Til dato</div>
              <DatePicker value={futureDate(ministery.toDateTime)} onChange={(v) => handleChange("toDateTime", v) } minDate={ministery.fromDateTime}/>

              <div className="form-text">Departementets ansvar</div>
              <TextField
                multiline
                rows={4}
                variant="outlined"
                value={ministery.ansvarsomrade ?? ""}
                onChange={(e) => handleChange("ansvarsomrade", e.target.value)}
              />
              <div className="form-text">Departementets ansvar på engelsk</div>
              <TextField
                multiline
                rows={4}
                variant="outlined"
                value={ministery.ansvarsomradeInEnglish ?? ""}
                onChange={(e) =>
                  handleChange("ansvarsomradeInEnglish", e.target.value)
                }
              />
              <div className="form-text">Historisk kommentar</div>
              <TextField
                multiline
                rows={4}
                variant="outlined"
                value={ministery.historicalComment ?? ""}
                onChange={(e) =>
                  handleChange("historicalComment", e.target.value)
                }
              />
              <div className="form-text">Historisk kommentar på engelsk</div>
              <TextField
                multiline
                rows={4}
                variant="outlined"
                value={ministery.historicalCommentInEnglish ?? ""}
                onChange={(e) =>
                  handleChange("historicalCommentInEnglish", e.target.value)
                }
              />
              <div className="form-text">Avviksmerknad</div>
              <TextField
                multiline
                rows={2}
                variant="outlined"
                value={ministery.note ?? ""}
                onChange={(e) => handleChange("note", e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="form-actions">
              <Button
                id="lagre"
                onClick={onSubmit}
                className="submit-button"
                value="Submit"
              >
                {isNewDep ? "Lagre og opprett" : "Lagre og oppdater"}
              </Button>

              <Button
                id="avbryt"
                className="avbryt-button"
                onClick={() => handleOpen()}
              >
                Avbryt
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
      {res && !res.succeeded && (
        <Snackbar open={openSnack} autoHideDuration={4000}>
          <Alert severity={"error"}>
            {isNewDep
              ? "Departement ble ikke opprettet"
              : "Endringende dine ble ikke lagret, prøv igjen"}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
