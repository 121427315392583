import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import "date-fns";
import { Button } from "reactstrap";
import "../style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import { editDataAuth, postDataAuth, putDataAuth } from "../../functionality/FetchApiData";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { validatePartiFields, validatePersonFields } from "../../functionality/ValidateFormFields";
import { PartiesFormField } from "../parties/PartiesFormField";

export default function PersonForm({
  open,
  handleOpenStatus,
  data,
  parti,
  options,
  refresh
}) {
  const [person, setPerson] = useState({});
  const [parties, setParties] = useState(parti)
  const [isNewPers, setIsNewPers] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [res, setRes] = useState();
  const [alerts, setAlerts] = useState(false);
  const history = useHistory();

  const handleChange = (variable, newValue) => {
    setPerson({ ...person, [variable]: newValue });
    setAlerts(false);
  };

  useEffect(() => {
	if (data && !person.firstName && !person.partimedlemskap) {
		setPerson(data);
		setIsNewPers(false);
	  }
  }, [])
  useEffect(() => {
	if(!parties) setParties([{}])
  }, [parties])

  const selectDefault = (optionList, personVariable) => {
    if (personVariable) {
      return optionList.findIndex((option) => option.value === personVariable);
    } else return null;
  };

  const handleOpen = () => {
	    setFormDisabled(false)
		handleOpenStatus()
		setPerson(data)
		setParties(parti)
	};

	const handlePartiChange = (parties) => {
		setParties(parties)
		setAlerts(false);
	}

  async function onSubmit() {
    if (validatePersonFields(person, setAlerts) || (validatePartiFields(parties.filter(p=>!p.delete), setAlerts))) {
      return;
    } else {
		setFormDisabled(true)
      if (isNewPers) {
        const response = await postDataAuth("persons", person);
        setRes(response);
        if (response.succeeded) {
			const data = parties.map((p)=> ({ ...p , personId: response.data.id, }))
			await putDataAuth("political-parties-memberships", data);
          	history.push(`regjeringspolitiker/${response.data.id}`);
        } else {
          setOpenSnack(true);
          setTimeout(() => {
            setOpenSnack(false);
            handleOpen();
          }, 4000);
        }
      } else {
		const data = parties.map((p)=>{
			const {politiskParti, person, ...d} = p;
			return d
		})

		const putRes = await putDataAuth("political-parties-memberships", data);
        const response = await editDataAuth("persons", person.id, person);
        setRes(response);
        if (response.succeeded) {
         	await refresh();
			handleOpenStatus();
			setFormDisabled(false);
        } else {
          setOpenSnack(true);
          setTimeout(() => {
            setOpenSnack(false);
            handleOpen();
          }, 4000);
        }
      }
    }
	}

  return (
    <div>
      {options.gender && options.parti ? (
        <Dialog
          open={open}
          onClose={handleOpen}
          aria-labelledby="form-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title" className="dialog-title-form">
            <div className="dialog-title">
              {isNewPers ? (
                <h3>Ny person</h3>
              ) : (
                <h3>Rediger personopplysninger</h3>
              )}
              <IconButton className="close-icon" onClick={handleOpen}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <form>
            <DialogContent
              className="dialog-person"
              dividers={false}
              tabIndex={-1}
            >
              <div className="form">
                <div className="form-text-alert">
                  {alerts
                    ? "Fyll ut alle påkrevde felter for å opprette personen"
                    : null}
                </div>
                <div>{!isNewPers ? "ID: " + person.id : ""}</div>
                <div
                  className={
                    !person.firstName && alerts
                      ? "form-text-alert"
                      : "form-text"
                  }
                >
                  Fornavn *
                </div>
                <TextField
                  id="name"
                  className="small-text-field"
                  required
                  value={person.firstName ?? ""}
                  variant="outlined"
                  onChange={(e) => handleChange("firstName", e.target.value)}
                />
                <div
                  className={
                    !person.lastName && alerts ? "form-text-alert" : "form-text"
                  }
                >
                  Etternavn *
                </div>

                <TextField
                  id="enLastName"
                  className="small-text-field"
                  required
                  value={person.lastName ?? ""}
                  variant="outlined"
                  onChange={(e) => handleChange("lastName", e.target.value)}
                />
				<PartiesFormField 
					parties={parties} 
					options={options.parti} 
					handleChange={handlePartiChange} 
					personId={person.id} 
					error={parties?.filter(p=>!p.partiId || !p.fromDateTime).length && alerts ? true : false} /> 
                <div
                  className={
                    !person.genderTypeId && alerts
                      ? "form-text-alert"
                      : "form-text"
                  }
                >
                  Kjønn *
                </div>
                <Select
                  required
                  className="small-text-field"
                  helperText="Feltet er påkrevd"
                  error={!person.genderTypeId && alerts ? true : false}
                  defaultValue={
                    options.gender[
                      selectDefault(options.gender, person?.genderTypeId ?? 0)
                    ]
                  }
                  options={options.gender}
                  onChange={(v) => handleChange("genderTypeId", v.value)}
                />
                <div className="form-text">Fødselsår</div>
                <TextField
                  className="small-text-field"
                  variant="outlined"
                  type="number"
                  value={person.birthYear ?? ""}
                  onChange={(e) => handleChange("birthYear", e.target.value)}
                />
                <div className="form-text">Avviksmerknad</div>
                <TextField
                  multiline
                  rows={2}
                  variant="outlined"
                  value={person.note ?? ""}
                  onChange={(e) => handleChange("note", e.target.value)}
                />
              </div>
            </DialogContent>

            <DialogActions>
              <div className="form-actions">
                <Button
                  id="lagre"
                  onClick={onSubmit}
                  className="submit-button"
                  value="Submit"
				  disabled={formDisabled}
                >
                  {isNewPers ? "Lagre og opprett" : "Lagre og oppdater"}
                </Button>

                <Button
                  id="avbryt"
                  className="avbryt-button"
                  onClick={handleOpen}
                >
                  Avbryt
                </Button>
              </div>
            </DialogActions>
          </form>
        </Dialog>
      ) : (
        <div> Loading</div>
      )}
      {res && !res.succeeded && (
        <Snackbar open={openSnack} autoHideDuration={4000}>
          <Alert severity={"error"}>
            {isNewPers
              ? "Partiet ble ikke opprettet"
              : "Endringende dine ble ikke lagret, prøv igjen"}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
