export function validateDepartmentFields(ministery, setAlerts) {
  if (
    !ministery.departementName ||
    !ministery.departementCode ||
    !ministery.departementNameInEnglish ||
    !ministery.fromDateTime
  ) {
    setAlerts(true);
    return true;
  } else {
    setAlerts(false);
    return false;
  }
}

export function validateGovernmentFields(government, setAlerts) {
  if (
    !government.regjeringName ||
    !government.regjeringCode ||
    !government.regjeringNameInEnglish ||
    !government.parlamentariskGrunnlagTypeId ||
    !government.fromDateTime
  ) {
    setAlerts(true);
    return true;
  } else {
    setAlerts(false);
    return false;
  }
}
export function validatePersonFields(person, setAlerts) {
  if (!person.firstName || !person.lastName || !person.genderTypeId) {
    setAlerts(true);
    return true;
  } else {
    setAlerts(false);
    return false;
  }
}
export function validatePartiFields(parties, setAlerts) {
	let isProblem = false
	parties.forEach(p=>{
			if(!p.partiId) isProblem = true
			if(!p.fromDateTime && !p.toDateTime) isProblem = true
	})
	setAlerts(isProblem)
	return isProblem
}
export function validateRadgiverFields(role, setAlerts) {
  if (
    !role.regjeringId ||
    !role.statsradId ||
    !role.politiskRadgiverTypeId ||
    !role.fromDateTime
  ) {
    setAlerts(true);
    return true;
  } else {
    setAlerts(false);
    return false;
  }
}
export function validateSekreterFields(role, setAlerts) {
  if (
    !role.regjeringId ||
    !role.statsradId ||
    !role.statsSekreterTypeId ||
    !role.fromDateTime
  ) {
    setAlerts(true);
    return true;
  } else {
    setAlerts(false);
    return false;
  }
}

export function validateStatsradFields(role, setAlerts) {
  if (
    !role.regjeringId ||
    !role.departementId ||
    !role.statsradTypeId ||
    !role.fromDateTime
  ) {
    setAlerts(true);
    return true;
  } else {
    setAlerts(false);
    return false;
  }
}
export function validatePartyFields(party, setAlerts) {
  if (
    !party.partiCode ||
    !party.partiName ||
    !party.partiNameInEnglish ||
    !party.fromDateTime
  ) {
    setAlerts(true);
    return true;
  } else {
    setAlerts(false);
    return false;
  }
}
