import React, { useState, useEffect } from "react";
import "../../style.css";
import Select from "react-select";
import "date-fns";
import { TextField } from "@material-ui/core";
import Button from "reactstrap/lib/Button";
import { editDataAuth, postDataAuth } from "../../../functionality/FetchApiData";
import { getStatsråds } from "../../../functionality/SetOptions";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { validateSekreterFields } from "../../../functionality/ValidateFormFields";
import { DatePicker } from "../../DatePicker";
import { futureDate } from "../../../functionality/TextHandler";

export default function StatssekreterForm({
  personId,
  data,
  options,
  setOpen,
}) {
  const [role, setRole] = useState({});
  const [isNewRole, setIsNewRole] = useState(true);
  const [statsrads, setStatsrads] = useState();
  const [regjeringId, setRegjeringId] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [res, setRes] = useState();
  const [alerts, setAlerts] = useState(false);

  if (data && options && !role.personId) {
    setRole(data);
    setIsNewRole(false);
    setRegjeringId(data.regjeringId);
  }
  useEffect(() => {
    getStatsråds({ setStatsrads, regjeringId });
  }, [regjeringId]);

  const selectDefault = (optionList, personVariable) => {
    if (personVariable) {
      return optionList.findIndex((option) => option.value === personVariable);
    } else return null;
  };

  function refreshPage() {
    window.location.reload();
  }
  const onSubmit = async () => {
    if (validateSekreterFields(role, setAlerts)) {
      return;
    } else {
      if (isNewRole) {
        const result = await postDataAuth("state-secretaries", {
          ...role,
          ["personId"]: personId,
        });
        setRes(result);
        if (result.succeeded) {
          refreshPage();
        } else {
          setOpenSnack(true);
          setTimeout(() => {
            setOpenSnack(false);
            setOpen(false);
          }, 3000);
        }
      } else {
        const result = await editDataAuth("state-secretaries", role.id, {
          ...role,
          ["personId"]: data.personId,
        });
        setRes(result);
        if (result.succeeded) {
          refreshPage();
        } else {
          setOpenSnack(true);
          setTimeout(() => {
            setOpenSnack(false);
            setOpen(false);
          }, 4000);
        }
      }
    }
  };

  const handleChange = (variable, newValue) => {
    setRole({ ...role, [variable]: newValue });
  };

  return (
    <div>
      {options && (
        <div className="form">
          <div className="form-text-alert">
            {alerts
              ? "Fyll ut alle påkrevde felter for å opprette rollen"
              : null}
          </div>
          <div
            className={
              !role.regjeringId && alerts ? "form-text-alert" : "form-text"
            }
          >
            Regjering *
          </div>
          <Select
            className="med-text-field"
            defaultValue={
              options.regjering[
                selectDefault(options.regjering, role?.regjeringId)
              ]
            }
            options={options.regjering}
            onChange={(v) => {
              setRegjeringId(v.value);
              return handleChange("regjeringId", v.value);
            }}
          />
          {statsrads && (
            <div>
              <div
                className={
                  !role.statsradId && alerts ? "form-text-alert" : "form-text"
                }
              >
                Statsråd *
              </div>

              <Select
                className="med-text-field"
                defaultValue={
                  statsrads[selectDefault(statsrads, role?.statsradId)]
                }
                options={statsrads}
                onChange={(v) => handleChange("statsradId", v.value)}
              />
            </div>
          )}
          <div
            className={
              !role.statsSekreterTypeId && alerts
                ? "form-text-alert"
                : "form-text"
            }
          >
            Statssekretær type *
          </div>
          <Select
            className="small-text-field"
            defaultValue={
              options.statssekreter[
                selectDefault(options.statssekreter, role?.statsSekreterTypeId)
              ]
            }
            options={options.statssekreter}
            onChange={(v) => handleChange("statsSekreterTypeId", v.value)}
          />
          <div
            className={
              !role.fromDateTime && alerts ? "form-date-alert" : "form-date"
            }
          >
            Fra dato *
          </div>
		  <DatePicker value={role.fromDateTime ?? null} onChange={(v) => handleChange("fromDateTime", v)}/>
          <div className="form-date">Til dato</div>
          <DatePicker value={futureDate(role.toDateTime)} onChange={(v) => handleChange("toDateTime", v) } minDate={role.fromDateTime}/>
		  <div className="form-text">Avviksmerknad</div>
          <TextField
            multiline
            rows={3}
            variant="outlined"
            value={role?.note || ""}
            onChange={(e) => handleChange("note", e.target.value)}
          />
          <div>
            <Button
              id="lagre"
              className="submit-button"
              onClick={onSubmit}
              value="Submit"
            >
              Lagre
            </Button>
            <Button
              id="avbryt"
              className="avbryt-button"
              onClick={() => setOpen(false)}
            >
              Avbryt
            </Button>
          </div>
        </div>
      )}
      {res && !res.succeeded && (
        <Snackbar open={openSnack} autoHideDuration={4000}>
          <Alert severity={"error"}>
            {isNewRole
              ? "Rollen ble ikke opprettet"
              : "Endringende dine ble ikke lagret, prøv igjen"}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
