import React, { useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";


export const DatePicker = ({value, onChange, minDate, maxDate, className, dateFormat}) => {
  const format = "DD.MM.yyyy"
  const [selectedDate, setDate] = useState(value ? moment(value) : null);
  const [inputValue, setInputValue] = useState(null);
  React.useEffect(() => {
    setInputValue(value ? moment(value).format(format) : null)  }, [value])
  React.useEffect(() => {
	  onChange(selectedDate ? moment(selectedDate).format(dateFormat) : null)
  }, [selectedDate])

  const onDateChange = (date, value) => {
    setDate(date)
    setInputValue(value)
  };




  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          format={"dd.MM.yyyy"}
		  margin="normal"
		  className={className }
		  inputVariant="outlined"
          value={selectedDate}
		  minDate={minDate ? moment(minDate) : moment("1813-12-31T21:11:54")}
		  maxDate={maxDate ? moment(maxDate) : undefined}
          autoOk={true}
          inputValue={inputValue}
          onChange={onDateChange}
		  invalidDateMessage={"Feil format. DD.MM.ÅÅÅÅ"}
		  KeyboardButtonProps={{
			"aria-label": "change date",
		  }}
        />
      </MuiPickersUtilsProvider>
  );
}

