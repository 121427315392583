import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <div className="text-box">
        <img className="logo" src={require("../assets/logo-footer.png")}></img>
        <div className="footer-text">Regjeringer siden 1814</div>
        <div className="footer-text">Admin</div>

        <div className="footer-info">
          <div>
            Departementene sikkerhets- og <br />
            serviceorganisasjon (DSS)
          </div>
          <br />
          <div>Statsministerens kontor (SMK)</div>
        </div>
      </div>
    </div>
  );
}
