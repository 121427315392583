import Select from "react-select";
import React from 'react'
import { DatePicker } from "../DatePicker";
import moment from "moment";

export const PartiesFormField = ({parties, options, handleChange, personId, error}) => {
	const dateFormat = "yyyy-MM-DDTHH:mm:ss+00:00";
	const getParti = (partiId) => options[options.findIndex((option) => option?.value === partiId)]
	const handlePartiChange = async (indx, newPartiId) => {
		const deleted = parties.filter(p=>p.delete)
		const toChange = parties.filter(p=>!p.delete).map((p, i) => i !== indx ? p : {...p, partiId: newPartiId, personId  } )
		handleChange([...toChange, ...deleted])
	}
	const handlePartiRemove = async (index) => {
		const tempParties = [...parties]
		let removed = tempParties.splice(index, 1);
		if(removed[0]?.id) tempParties.push({ id: removed[0].id, delete: true });
		tempParties.filter(p=>!p.delete)[tempParties.filter(p=>!p.delete).length - 1].toDateTime = null;
		handleChange(tempParties)
	}
	const handleDateChange = (newDate, index) => {
		const deleted = parties.filter(p=>p.delete)
		const toChange = parties.filter(p=>!p.delete).map( (p, i) => i=== index ? {...p, fromDateTime: newDate} : p)
		const withToDateTime = toChange.map( (p, i, a) => a[i + 1]?.fromDateTime ? {...p, toDateTime:  moment(a[i + 1]?.fromDateTime).subtract(1, 'd').format(dateFormat)} : p )
		handleChange([...withToDateTime,...deleted])
	}
	const hendleAddParti = (e) => {
		e.preventDefault();
		handleChange([...parties, {}])
	}
	return <div style={{border: "solid transparent 2px"}}>
		{parties?.filter(p=>!p.delete).map((p,i,a)=> <div key={i}>
			<div style={{display: "flex", justifyContent: "flex-start", gap: "20px"}}>
				<div style={{width: "40%"}} className="input-wrap">
					<p className={(error && !p.partiId ?  "form-text-alert":  "form-text") + " input-wrap_title"}>Partimedlemskap *</p>
					<Select
						required
						className="input-field-select"
						helperText="Feltet er påkrevd"
						defaultValue={getParti(p?.partiId)}
						maxMenuHeight={230}
						error={error}
						options={options}
						onChange={(v) => handlePartiChange(i, v.value)}
					/>
				</div>
				<div className="input-wrap">
					<p className={( error && !p.fromDateTime ?  "form-text-alert":  "form-text")}>Fra dato *</p>
					<DatePicker 
						value={p?.fromDateTime} 
						onChange={(newDate)=>handleDateChange(newDate, i)} 
						minDate={a[i - 1]?.fromDateTime ? moment(a[i - 1]?.fromDateTime).add(1, 'd').format(dateFormat) : undefined}
						maxDate={a[i + 1]?.fromDateTime ? moment(a[i + 1]?.fromDateTime).subtract(1, 'd').format(dateFormat) : undefined}
						dateFormat={dateFormat}
						className={"input-field-date"} />
				</div>
				{( i > 0 && i === a.length - 1 ) && <button type="button" onClick={()=>handlePartiRemove(i)} className="form-btn">fjern</button>}
			</div>
		</div>)}
		<button onClick={(e)=>hendleAddParti(e)} className="form-btn">Legg til partimedlemskap</button>
	</div>
}