import React, { useState } from "react";
import "../../style.css";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PolitiskradgiverForm from "./Politiskradgiver";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import StatssekreterForm from "./Statssekreter";
import StatsradForm from "./Statsrad";
import { Button } from "reactstrap";
import AddCircleIcon from "@material-ui/icons/AddCircle";

export default function PositionForm({
  personId,
  data,
  options,
  setOpen,
  type,
}) {
  const [role, setRole] = useState({});
  const [roleType, setRoleType] = useState("1");
  const [isNewRole, setIsNewRole] = useState(true);

  if (data && options && !role.personId) {
    setRole(data);
    setIsNewRole(false);
    if (type) {
      setRoleType(type);
    }
  }

  const handleChange = (variable, newValue) => {
    setRole({ ...role, [variable]: newValue });
  };

  return (
    <div>
      <div className="form-text-header"> {!data && "Politisk rolle"}</div>
      {isNewRole && (
        <RadioGroup
          className="politisk-radio"
          onChange={(e, v) => {
            setRoleType(v);
            return handleChange("rolle", v);
          }}
          defaultValue={roleType ?? type}
        >
          <FormControlLabel value="1" control={<Radio />} label="Statsråd" />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="Statssekretær"
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label="Politisk rådgiver"
          />
        </RadioGroup>
      )}
      {options && (
        <div>
          {roleType === "1" && (
            <StatsradForm
              personId={personId ?? 0}
              data={data ?? ""}
              options={options}
              setOpen={setOpen}
            />
          )}
          {roleType === "3" && (
            <PolitiskradgiverForm
              personId={personId ?? 0}
              data={data ?? ""}
              options={options}
              setOpen={setOpen}
            />
          )}
          {roleType === "2" && (
            <StatssekreterForm
              personId={personId ?? 0}
              data={data ?? ""}
              options={options}
              setOpen={setOpen}
            />
          )}
        </div>
      )}
    </div>
  );
}

export function PositionModal({ personId, data, options, type }) {
  const [open, setOpen] = useState(false);
  const handleOpenStatus = () => {
    setOpen(!open);
  };
  return (
    <div>
      {data ? (
        <Button className="rediger-button" onClick={handleOpenStatus}>
          Rediger rolle
        </Button>
      ) : (
        <Button id="lagre" className="form-button" onClick={handleOpenStatus}>
          Registrer ny rolle
          <AddCircleIcon className="button-icon" />
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleOpenStatus}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="dialog-title-form">
          {data
            ? "Rediger rolle  - " +
              data.person.firstName +
              " " +
              data.person.lastName
            : "Ny rolle"}
        </DialogTitle>

        <form>
          <DialogContent>
            <PositionForm
              data={data}
              personId={personId}
              type={type}
              options={options}
              setOpen={setOpen}
            />
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}
