import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

const config = require("./appEndpoints.json");
/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
const acquireAccessToken = async (msalInstance) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

 if (!activeAccount && accounts.length === 0) {
	console.log(`no active account`)
	return
 }

  //  TODO 
  // {
  // 	* User is not signed in. Throw error or wait for user to login.
  // 	* Do not attempt to log a user in outside of the context of MsalProvider
  // 	*/
  // }
  const request = {
    account: activeAccount || accounts[0],
    loginRequest: config.msalAPI.loginRequest,
  };
  const authResult = await msalInstance.acquireTokenSilent(request).then( r=>r ).catch(e=>console.log(e)) ;

  return authResult.accessToken;
};

const getToken = async () => {
	const msalInstance = new PublicClientApplication(msalConfig);
	const authToken = await acquireAccessToken(msalInstance);
	if (authToken) return authToken;
  };

export async function fetchAuthData(endpoint, options) {
	
	const bearer = `Bearer ${await getToken()}`;
	if(!options){
		options = { method:"GET", mode: "cors" }
	}
	const opt = { ...options, headers: {...options?.headers, Authorization: bearer } };
	const fetchFrom = `${config.adminAPI.url}/${endpoint}`
  	return fetch(fetchFrom, opt)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
		console.log("error",error)
		return error
	});
}


/* 

 A silent sign-in request was sent but no user is signed in.
Trace ID: 9a52ee63-8d73-4af4-bc39-a508852a1d00
Correlation ID: a35cadff-8b58-41f3-ba8e-517b7cd22c95
Timestamp: 2021-09-19 11:50:30Z

*/