import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
export default function Loading() {
  return (
    <div className="fullscreen-loading">
      <CircularProgress size={100} thickness={2.5} />
      <div className="loading-text"> Laster...</div>
    </div>
  );
}
