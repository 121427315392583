import React, { useEffect, useState } from "react";
import Divider from "@material-ui/core/Divider";
import InfoLine from "../InfoLine";
import RoleView from "../RoleView";
import PersonForm from "./PersonForm";
import { PositionModal } from "./postitionForms/PositionForm";
import { Button } from "reactstrap";
import {
  deleteDataAuth,
  fetchDataElementAuth,
  fetchQueryAuth,
} from "../../functionality/FetchApiData";
import { useParams, Link } from "react-router-dom";
import {fetchOptions} from "../../functionality/FetchOptions";
import Loading from "../Loading";
import DeleteWarning from "../DeleteWarning";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { useHistory } from "react-router-dom";
import { capslockTextFormat } from "../../functionality/TextHandler";

export default function PersonDetail() {
	const { personId } = useParams();
	const history = useHistory();
  
	const [person, setPerson] = useState();
	const [partis, setPartis] = useState([]);

  // getRoles
  const [options, setOptions] = useState();
  const [statsradRoles, setStatsradRoles] = useState();
  const [politiskRoles, setPolitiskRoles] = useState();
  const [sekreterRoles, setSekreterRoles] = useState();
  
  const [openSnack, setOpenSnack] = useState(false);
  const [del, setDel] = useState();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    getPersonInfo();
    fetch();
  }, []);

  async function getPersonInfo() {
	const person = await fetchDataElementAuth("persons", personId)
	if(person) {
		let perosnsPartis = await fetchQueryAuth("political-parties-memberships", "personId", personId)
		if(perosnsPartis?.length) {
			perosnsPartis.sort((first, secound) => first.fromDateTime > secound.fromDateTime ? 1 : -1 )
		}
		setPerson(person?.firstName ? person : { });
		setPartis(perosnsPartis?.length ? perosnsPartis : [{}]);
	} else {
		history.push("/regjeringspolitiker")
	}
  }
  async function fetch() {
	if (!statsradRoles && !politiskRoles && !sekreterRoles) {
		getRoles();
	}
    setOptions(await fetchOptions());
  }

  async function getRoles() {
	if (personId) {
	  setStatsradRoles(await fetchQueryAuth("ministers", "personId", personId));
	  setPolitiskRoles(
		await fetchQueryAuth("political-advisors", "personId", personId)
	  );
	  setSekreterRoles(
		await fetchQueryAuth("state-secretaries", "personId", personId)
	  );
	} 
  }
  const onDelete = async () => {
    const res = await deleteDataAuth("persons", person.id);
    setOpenSnack(true);
    setDel(res);
    if (res.succeeded) {
      setTimeout(() => {
        setOpenSnack(false);
        history.replace(`/regjeringspolitiker`);
      }, 3000);
    } else {
      setTimeout(() => setOpenSnack(false), 6000);
    }
  };

  const handleOpenStatus = () => {
    setOpen(!open);
  };

  return (
    <div>
      {person && options ? (
        <div className="detail-page">
          <Link
            role="button"
            className="tilbake-btn"
            tabIndex={0}
            to="/regjeringspolitiker"
          >
            <p>Tilbake</p>
          </Link>
          <h1>{person.firstName + " " + person.lastName}</h1>

          <div className="main-info">
            <div className="info-page">
              <InfoLine header="Person ID:" info={person.id} />
              <InfoLine
                header="Navn:"
                info={person.firstName + " " + person.lastName}
              />
              <InfoLine header="Født:" info={person.birthYear} />
              <InfoLine
                header="Kjønn:"
                info={
                  capslockTextFormat(person.genderType?.gender) ??
                  capslockTextFormat(
                    options.gender.filter(
                      (gender) => (gender.value = person.genderTypeId)
                    )?.gender
                  )
                }
              />
              <InfoLine
                header="Partimedlemskap:"
                info={
                  partis
                    ? partis[partis?.length - 1]?.politiskParti?.partiName
                    : "Ukjent"
                }
              />
              {person.note && (
                <InfoLine
                  header="Avviksmerknad:"
                  info={person.note}
                  alert={true}
                />
              )}
              <div className="edit-detail">
                <Button className="rediger-button" onClick={handleOpenStatus}>
                  Rediger person
                </Button>
                <DeleteWarning name="person" prop="en" onDelete={onDelete} />
              </div>
            </div>

            <PersonForm
              open={open}
              handleOpenStatus={handleOpenStatus}
              data={person}
              options={options}
              parti={partis}
              refresh={getPersonInfo}
            />
          </div>
          <div className="divider">
            <Divider />
          </div>
          <h1>Registrerte roller</h1>
          <PositionModal data="" personId={person.id} options={options} />
          {statsradRoles &&
            statsradRoles.map((statsrad, i) => (
              <RoleView role={statsrad} type={"1"} options={options} key={i} />
            ))}
          {politiskRoles &&
            politiskRoles.map((politisk, i) => (
              <RoleView role={politisk} type={"3"} options={options} key={i} />
            ))}
          {sekreterRoles &&
            sekreterRoles.map((sekreter, i) => (
              <RoleView role={sekreter} type={"2"} options={options} key={i} />
            ))}
        </div>
      ) : (
		<>	
			<Loading />
		</>
      )}
      {del && (
        <Snackbar open={openSnack} autoHideDuration={4000}>
          <Alert severity={del.succeeded ? "success" : "error"}>
            {del.succeeded
              ? "Personen ble slettet"
              : "Personen ble ikke slettet, prøv igjen"}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
