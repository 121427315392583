import { fetchData, fetchDoubleQueryAuth } from "./FetchApiData";
import { capslockTextFormat } from "./TextHandler";

export const fetchOptions = async() => {
  const types = await fetchData("r1814-types");
  if(types){
	const options = {
		gender: types?.genderTypes?.map((opt) => ({
		  value: opt.id,
		  label: capslockTextFormat(opt.gender),
		})),
		parti: (await fetchDoubleQueryAuth("political-parties", "all=true"))?.data?.map(
		  (opt) => ({
			value: opt.id,
			label: opt.partiName,
		  })
		),
		statsrads: (await fetchDoubleQueryAuth("ministers", "all=true"))?.data?.map(
		  (opt) => ({
			value: opt.id,
			label: `${opt?.statsradTitle}  -  ${opt.person.firstName}  ${
			  opt?.person?.lastName
			} - ${opt?.statsradType?.type} - ${opt?.fromDateTime?.substring(
			  0,
			  5
			)} ${opt?.toDateTime?.substring(0, 4)}`,
		  })
		),
		statsradType: (await types)?.statsradTypes?.map((opt) => ({
		  value: opt.id,
		  label: capslockTextFormat(opt?.type),
		})),
		politiskradgiver: (await types)?.radgiverTypes?.map((opt) => ({
		  value: opt.id,
		  label: capslockTextFormat(opt?.type),
		})),
		statssekreter: (await types)?.sekreterTypes?.map((opt) => ({
		  value: opt.id,
		  label: capslockTextFormat(opt?.type),
		})),
		regjering: (await fetchDoubleQueryAuth("governments", "all=true"))?.data.map(
		  (opt) => ({
			value: opt?.id,
			label: opt?.regjeringName,
		  })
		),
		departement: (await fetchDoubleQueryAuth("departments", "all=true")).data?.map(
		  (opt) => ({
			value: opt?.id,
			label: opt?.departementName,
		  })
		),
	  };
	
	  return options;
  } else {
	  return undefined
  }
  
}
