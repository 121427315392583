import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { Button } from "reactstrap";
import "../style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from "@material-ui/core";
import { editDataAuth, postDataAuth } from "../../functionality/FetchApiData";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { validatePartyFields } from "../../functionality/ValidateFormFields";
import { futureDate } from "../../functionality/TextHandler";
import { DatePicker } from "../DatePicker";

export default function PartyForm({ data, refresh, open, handleOpenStatus }) {
  const [party, setParty] = useState({});
  const [isNewDep, setIsNewDep] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [res, setRes] = useState();
  const history = useHistory();
  const [alerts, setAlerts] = useState(false);

  React.useEffect(()=>{
	if (data && !party.partiName) {
		setParty(data);
		setIsNewDep(false);
	  }
  }, [])

  const handleOpen = () => {
	handleOpenStatus()
	setParty(data ? data : {});
	setIsNewDep(data?.regjeringName ? true : false);
	setAlerts(false);
  }

  const handleChange = (variable, newValue) => {
    setParty({ ...party, [variable]: newValue });
  };

  async function onSubmit() {
    if (validatePartyFields(party, setAlerts)) {
      return;
    } else {
      if (isNewDep) {
        const response = await postDataAuth("political-parties", party);
        setRes(response);
        if (response.succeeded) {
          history.push(`partier/${response.data.id}`);
        } else {
          setOpenSnack(true);
          setTimeout(() => {
            setOpenSnack(false);
            handleOpen();
          }, 4000);
        }
      } else {
        const response = await editDataAuth("political-parties", party.id, party);
        setRes(response);
        if (response.succeeded) {
          if (refresh) {
            refresh();
          } else {
            history.push(`partier/${party.id}`);
          }
        } else {
          setOpenSnack(true);
          setTimeout(() => {
            setOpenSnack(false);
            handleOpen();
          }, 4000);
        }
      }
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleOpen}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <div className="dialog-title">
            {isNewDep ? <h3>Nytt parti </h3> : <h3>Rediger parti</h3>}
            <IconButton className="close-icon" onClick={handleOpen}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form>
          <DialogContent>
            <div className="form">
              {alerts && (
                <div className="form-text-alert">
                  "Fyll ut alle påkrevde felter for å opprette partiet"
                </div>
              )}
              <div
                className={
                  !party.partiCode && alerts ? "form-text-alert" : "form-text"
                }
              >
                Partikode *
              </div>
              <TextField
                id="code"
                className="small-text-field"
                value={party.partiCode ?? ""}
                variant="outlined"
                onChange={(e) => handleChange("partiCode", e.target.value)}
              />
              <div
                className={
                  !party.partiName && alerts ? "form-text-alert" : "form-text"
                }
              >
                Partinavn *
              </div>
              <TextField
                id="name"
                className="med-text-field"
                value={party.partiName ?? ""}
                variant="outlined"
                onChange={(e) => handleChange("partiName", e.target.value)}
              />
              <div
                className={
                  !party.partiNameInEnglish && alerts
                    ? "form-text-alert"
                    : "form-text"
                }
              >
                Partinavn på engelsk *
              </div>
              <TextField
                id="eng-name"
                className="med-text-field"
                value={party.partiNameInEnglish ?? ""}
                variant="outlined"
                onChange={(e) =>
                  handleChange("partiNameInEnglish", e.target.value)
                }
              />
              <div
                className={
                  !party.fromDateTime && alerts
                    ? "form-date-alert"
                    : "form-date"
                }
              >
                Fra dato *
              </div>
              <DatePicker value={party.fromDateTime ?? null} onChange={(v) => handleChange("fromDateTime", v)}/>
              <div className="form-date">Til dato</div>
              <DatePicker value={futureDate(party.toDateTime)} onChange={(v) => handleChange("toDateTime", v) } minDate={party.fromDateTime}/>
              <div className="form-text">Avviksmerknad</div>
              <TextField
                multiline
                rows={2}
                variant="outlined"
                value={party.note ?? ""}
                onChange={(e) => handleChange("note", e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="form-actions">
              <Button
                id="lagre"
                onClick={onSubmit}
                className="submit-button"
                value="Submit"
              >
                {isNewDep ? "Lagre og opprett" : "Lagre og oppdater"}
              </Button>

              <Button
                id="avbryt"
                className="avbryt-button"
                onClick={() => handleOpen()}
              >
                Avbryt
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
      {res && !res.succeeded && (
        <Snackbar open={openSnack} autoHideDuration={4000}>
          <Alert severity={"error"}>
            {isNewDep
              ? "Partiet ble ikke opprettet"
              : "Endringende dine ble ikke lagret, prøv igjen"}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
