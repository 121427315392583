import { fetchData } from "./FetchApiData";
import { capslockTextFormat } from "./TextHandler";

export default async function fetchRegjerings() {
  const regjeringOptions = {
    parlamentarisk: (
      await fetchData("governments/parlamentarisk-grunnlag-types")
    ).map((opt) => ({
      value: opt.id,
      label: capslockTextFormat(opt.type),
    })),
    fratredelse: (await fetchData("governments/fratredelsegrunn-types")).map(
      (opt) => ({
        value: opt.id,
        label: capslockTextFormat(opt.type),
      })
    ),
    tiltredelse: (await fetchData("governments/tiltredelsegrunn-types")).map(
      (opt) => ({
        value: opt.id,
        label: capslockTextFormat(opt.type),
      })
    ),
  };

  return regjeringOptions;
}
