import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import InfoLine from "../InfoLine";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Divider from "@material-ui/core/Divider";
import dateHandler, {
  capslockTextFormat,
} from "../../functionality/TextHandler";
import GovernmentForm from "./GovernmentForm";
import Loading from "../Loading";
import { useHistory } from "react-router-dom";
import DeleteWarning from "../DeleteWarning";
import fetchRegjerings from "../../functionality/FetchGovernment";
import Button from "reactstrap/lib/Button";
import { deleteDataAuth, fetchDataElement, fetchDataElementAuth } from "../../functionality/FetchApiData";

export default function GovernmentDetail() {
  const { govId } = useParams();
  const [regjering, setRegjering] = useState();
  const [regjeringOptions, setRegjeringOptions] = useState();
  const history = useHistory();
  const [openSnack, setOpenSnack] = useState(false);
  const [del, setDel] = useState();
  const [open, setOpen] = useState(false);
  const handleOpenStatus = () => {
    setOpen(!open);
  };

  useEffect(() => {
    getGovernment();
  }, []);

  async function getGovernment() {
	const gov = await fetchDataElementAuth("governments", govId);
	if(gov) setRegjering(gov);
	else history.push("/regjering");
    setRegjeringOptions(await fetchRegjerings());
  }

  const onDelete = async () => {
    const res = await deleteDataAuth("governments", regjering.id);
    setOpenSnack(true);
    setDel(res);
    if (res.succeeded) {
      setTimeout(() => {
        setOpenSnack(false);
        history.replace(`/regjering`);
      }, 3000);
    } else {
      setTimeout(() => setOpenSnack(false), 6000);
    }
  };

  function refreshPage() {
    window.location.reload();
  }
  return (
    <div>
      {regjering ? (
        <div className="detail-page">
          <Link className="tilbake-btn" tabIndex={0} to="/regjering">
            <p>Tilbake</p>
          </Link>
          <h1>{regjering.regjeringName}</h1>
          <div className="info-page">
            <div className="main-info">
              <div>
                <InfoLine header="Regjerings ID: " info={regjering.id} />
                <InfoLine
                  header="Regjeringskode: "
                  info={regjering.regjeringCode}
                />
                <InfoLine header="Navn:" info={regjering.regjeringName} />
                <InfoLine
                  header="Navn på engelsk:"
                  info={regjering.regjeringNameInEnglish}
                />
                <InfoLine
                  header="Parlamentarisk grunnlag:"
                  info={capslockTextFormat(
                    regjering.parlamentariskGrunnlagType.type
                  )}
                />
                <InfoLine
                  header="Tiltredelsesgrunn:"
                  info={capslockTextFormat(regjering.tiltredelseGrunnType.type)}
                />
                <InfoLine
                  header="Fratredelsesgrunn:"
                  info={capslockTextFormat(
                    regjering?.fratredelseGrunnType?.type
                  )}
                />

                <InfoLine
                  header="Fra dato:"
                  info={dateHandler(regjering?.fromDateTime)}
                />
                <InfoLine
                  header="Til dato:"
                  info={dateHandler(regjering?.toDateTime)}
                />
                <div className="divider">
                  <Divider />
                </div>
                <div>
                  <InfoLine
                    header="Avviksmerknad"
                    info={regjering.note}
                    vertical={true}
                  />
                </div>
              </div>
            </div>
            <div className="edit-detail">
              <GovernmentForm
                data={regjering}
                options={regjeringOptions}
                refresh={refreshPage}
                open={open}
                handleOpenStatus={handleOpenStatus}
              />
              <Button
                className="rediger-button"
                onClick={() => handleOpenStatus()}
              >
                Rediger regjering
              </Button>
              <DeleteWarning name="regjering" prop="en" onDelete={onDelete} />
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
      {del && (
        <Snackbar open={openSnack} autoHideDuration={4000}>
          <Alert severity={del.succeeded ? "success" : "error"}>
            {del.succeeded
              ? "Regjeringen ble slettet"
              : "Regjeringen ble ikke slettet, prøv igjen"}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
