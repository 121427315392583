import React, { useEffect, useState } from "react";
import PersonListItem from "./PersonListItem";
import TextField from "@material-ui/core/TextField";
import PersonForm from "./PersonForm";
import { fetchDoubleQueryAuth } from "../../functionality/FetchApiData";
import {fetchOptions} from "../../functionality/FetchOptions";
import Loading from "../Loading";
import Paginate from "../Pagination";
import { Divider } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Button } from "reactstrap";

export function PersonPage() {
  const [search, setSearch] = useState();
  const [searchData, setSearchData] = useState([]);
  const [options, setOptions] = useState();
  const [page, setPage] = useState(1);
  const [pSize, setPSize] = useState();
  const [totElements, setTotElements] = useState();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  const handleOpenStatus = () => {
    setOpen(!open);
  };
  useEffect(() => {
    searchPerson();
  }, [search, page]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  async function searchPerson() {
    let query = `pageNumber=${page}&pageSize=15&`;
    if (search) {
      query += `personName=${search}&`;
    }
    const { data, pageSize, totalRecords } = await fetchDoubleQueryAuth(
      "persons",
      query
    );
    setSearchData(data);
    setPSize(pageSize);
    setTotElements(totalRecords);
  }

  async function fetch() {
    setOptions(await fetchOptions());
  }
  function refreshPage() {
    window.location.reload();
  }
  return (
    <div>
      {options && searchData ? (
        <div className="departement">
          <h1>Regjeringspolitikere</h1>
          <Button id="lagre" className="form-button" onClick={handleOpenStatus}>
            Ny person
            <AddCircleIcon className="button-icon" />
          </Button>
          <PersonForm
            open={open}
            handleOpenStatus={handleOpenStatus}
            data=""
            options={options}
            refresh={refreshPage}
          />
          <TextField
            type="search"
            value={search}
            placeholder="Søk etter en regjeringspolitiker"
            variant="outlined"
            inputProps={{style: {fontSize: 20}}}
            onChange={(e) => setSearch(e.target.value)}
          />
          <br />
          <div className="search-info">
            {"Viser " +
              ((page - 1) * pSize + 1) +
              "-" +
              Math.min(page * pSize, totElements) +
              " av " +
              totElements +
              " treff."}
          </div>
          <Divider />
          <div className="list-show">
            {searchData.map((pers) => (
              <PersonListItem
                data={pers}
                key={pers.id}
                options={options}
                refresh={refreshPage}
              />
            ))}
          </div>
          <Paginate
            setPage={setPage}
            page={page}
            size={pSize ?? 1}
            totalElements={totElements}
          />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
