import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import "../style.css";
import Select from "react-select";
import { Dialog } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { IconButton, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Button from "reactstrap/lib/Button";
import { editDataAuth, postDataAuth } from "../../functionality/FetchApiData";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { validateGovernmentFields } from "../../functionality/ValidateFormFields";
import { futureDate } from "../../functionality/TextHandler";
import { DatePicker } from "../DatePicker";

export default function GovernmentForm({
  data,
  options,
  open,
  refresh,
  handleOpenStatus,
}) {
  const [government, setGovernment] = useState({});
  const [isNewGov, setIsNewGov] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [res, setRes] = useState();
  const history = useHistory();
  const [alerts, setAlerts] = useState(false);

  React.useEffect(()=>{
	if (data && !government.regjeringName) {
		setGovernment(data);
		setIsNewGov(false);
	}
  }, [])

  const handleChange = (variable, newValue) => {
    setGovernment({ ...government, [variable]: newValue });
  };

  const handleOpen = () => {
	handleOpenStatus()
	setGovernment(data ? data : {});
	setIsNewGov(data?.regjeringName ? true : false);
	setAlerts(false);
  }

  async function onSubmit() {
    if (validateGovernmentFields(government, setAlerts)) {
      return;
    } else {
      if (isNewGov) {
        const response = await postDataAuth("governments", government);
        setRes(response);
        if (response.succeeded) {
          history.replace(`regjering/${response.data.id}`);
        } else {
          setOpenSnack(true);
          setTimeout(() => setOpenSnack(false), 4000);
        }
      } else {
        const response = await editDataAuth(
          "governments",
          government.id,
          government
        );
        setRes(response);
        if (response.succeeded) {
          if (refresh) {
            refresh();
          } else {
            history.replace(`regjering/${government.id}`);
          }
        } else {
          setOpenSnack(true);
          setTimeout(() => setOpenSnack(false), 6000);
        }
      }
    }
  }

  const selectDefault = (optionList, personVariable) => {
    if (personVariable) {
      return optionList.findIndex((option) => option.value === personVariable);
    } else return null;
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleOpen}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title" className="dialog-title-form">
          <div className="dialog-title">
            {isNewGov ? <h3>Ny regjering </h3> : <h3>Rediger regjering</h3>}
            <IconButton className="close-icon" onClick={handleOpen}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <form>
          <DialogContent>
            <div className="form">
              <div className="form-text-alert">
                {alerts
                  ? "Fyll ut alle påkrevde felter for å opprette personen"
                  : null}
              </div>
              <div
                className={
                  !government.regjeringCode && alerts
                    ? "form-text-alert"
                    : "form-text"
                }
              >
                Regjeringskode *
              </div>
              <TextField
                className="small-text-field"
                id="standard-basic"
                variant="outlined"
                value={government.regjeringCode ?? ""}
                onChange={(e) => handleChange("regjeringCode", e.target.value)}
              />
              <div
                className={
                  !government.regjeringName && alerts
                    ? "form-text-alert"
                    : "form-text"
                }
              >
                Regjeringsnavn *
              </div>
              <TextField
                className="med-text-field"
                id="name"
                value={government.regjeringName ?? ""}
                variant="outlined"
                onChange={(e) => handleChange("regjeringName", e.target.value)}
              />
              <div
                className={
                  !government.regjeringNameInEnglish && alerts
                    ? "form-text-alert"
                    : "form-text"
                }
              >
                Regjerings navn på engelsk *
              </div>
              <TextField
                className="med-text-field"
                id="enName"
                value={government.regjeringNameInEnglish ?? ""}
                variant="outlined"
                onChange={(event) =>
                  handleChange("regjeringNameInEnglish", event.target.value)
                }
              />

              <div
                className={
                  !government.parlamentariskGrunnlagTypeId && alerts
                    ? "form-text-alert"
                    : "form-text"
                }
              >
                Parlamentarisk grunnlag *
              </div>
              {options ? (
                <div>
                  <Select
                    className="med-text-field"
                    options={options.parlamentarisk}
                    defaultValue={
                      options.parlamentarisk[
                        selectDefault(
                          options.parlamentarisk,
                          government.parlamentariskGrunnlagTypeId
                        )
                      ]
                    }
                    onChange={(v) =>
                      handleChange("parlamentariskGrunnlagTypeId", v.value)
                    }
                  />
                  <div
                    className={
                      !government.tiltredelseGrunnTypeId && alerts
                        ? "form-text-alert"
                        : "form-text"
                    }
                  >
                    Tiltredelsesgrunn 
                  </div>
                  <Select
                    labelId="select-label"
                    id="select-tiltredelse"
                    className="med-text-field"
                    options={[
						{ value: "", label: "Select..." },
						...options.tiltredelse,
					  ]}
                    defaultValue={
                      options.tiltredelse[
                        selectDefault(
                          options.tiltredelse,
                          government.tiltredelseGrunnTypeId || null
                        )
                      ]
                    }
                    onChange={(v) =>
                      handleChange("tiltredelseGrunnTypeId", v.value)
                    }
                  />
                  <div className="form-text">Fratredelsesgrunn</div>

                  <Select
                    labelId="select-label"
                    id="select-fratredelse"
                    className="med-text-field"
                    options={[
                      { value: "", label: "Select..." },
                      ...options.fratredelse,
                    ]}
                    defaultValue={
                      options.fratredelse[
                        selectDefault(
                          options.fratredelse,
                          government.fratredelseGrunnTypeId || null
                        )
                      ]
                    }
                    onChange={(v) =>
                      handleChange("fratredelseGrunnTypeId", v.value)
                    }
                  />
                </div>
              ) : null}
                <div
                  className={
                    !government.fromDateTime && alerts
                      ? "form-date-alert"
                      : "form-date"
                  }
                >
                  Fra dato *
                </div>
			  <DatePicker value={government.fromDateTime ?? null} onChange={(v) => handleChange("fromDateTime", v)}/>
              <div className="form-date">Til dato</div>
              <DatePicker value={futureDate(government.toDateTime)} onChange={(v) => handleChange("toDateTime", v) } minDate={government.fromDateTime}/>
              <div className="form-text">Avviksmerknad</div>
              <TextField
                multiline
                rows={2}
                variant="outlined"
                value={government.note ?? ""}
                onChange={(e) => handleChange("note", e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="form-actions">
              <Button
                id="lagre"
                onClick={onSubmit}
                className="submit-button"
                value="Submit"
              >
                {isNewGov ? "Lagre og opprett" : "Lagre og oppdater"}
              </Button>
              <Button
                id="avbryt"
                className="avbryt-button"
                onClick={() => handleOpen()}
              >
                Avbryt
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
      {res && !res.succeeded && (
        <Snackbar open={openSnack} autoHideDuration={4000}>
          <Alert severity={"error"}>
            {isNewGov
              ? "Regjeringen ble ikke opprettet"
              : "Endringende dine ble ikke lagret, prøv igjen"}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
