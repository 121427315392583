import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
const config = require("../appEndpoints.json");
/**
 * Renders a sign-out button
 */
export const SignButton = ({ isAuthenticated }) => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };
  const handleLogin = () => {
    instance.loginRedirect(config.msalAPI.loginRequest).catch((e) => {
      console.log(e);
    });
  };
  const handleClick = () => {
    isAuthenticated ? handleLogout() : handleLogin();
  };

  return (
    <Button
      variant="secondary"
      className="ml-auto"
      drop="left"
      title={isAuthenticated ? "Logg ut" : "Logg inn"}
      onClick={handleClick}
    >
      {isAuthenticated ? "Logg ut" : "Logg inn"}
    </Button>
  );
};
