import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchDataElementAuth, deleteDataAuth } from "../../functionality/FetchApiData";
import InfoLine from "../InfoLine";
import Divider from "@material-ui/core/Divider";
import dateHandler, { handleParagraphs } from "../../functionality/TextHandler";
import MinistryForm from "./MinistryForm";
import Loading from "../Loading";
import { useHistory } from "react-router-dom";
import DeleteWarning from "../DeleteWarning";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Button from "reactstrap/lib/Button";

export default function MinistryDetail() {
  const { id } = useParams();
  const [department, setDepartment] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [del, setDel] = useState();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const handleOpenStatus = () => {
    setOpen(!open);
  };

  useEffect(() => {
    getDepartment();
  }, []);
  async function getDepartment() {
	const department = await fetchDataElementAuth("departments", id);
	if(department) setDepartment(department);
	else history.push("/departement");
  }
  function refreshPage() {
    window.location.reload();
  }

  const onDelete = async () => {
    const res = await deleteDataAuth("departments", department.id);
    setOpenSnack(true);
    setDel(res);
    if (res.succeeded) {
      setTimeout(() => {
        setOpenSnack(false);
        history.replace(`/departement`);
      }, 3000);
    } else {
      setTimeout(() => setOpenSnack(false), 6000);
    }
  };

  return (
    <div>
      {department ? (
        <div className="detail-page">
          <Link className="tilbake-btn" tabIndex={0} to="/departement">
            <p>Tilbake</p>
          </Link>
          <h1>{department.departementName}</h1>
          <div className="info-page">
            <div className="main-info">
              <div>
                <InfoLine header="Departements ID: " info={department.id} />
                <InfoLine
                  header="Departementskode: "
                  info={department.departementCode}
                />
                <InfoLine header="Navn:" info={department.departementName} />
                <InfoLine
                  header="Navn på engelsk:"
                  info={department.departementNameInEnglish}
                />
                <InfoLine
                  header="Fra dato:"
                  info={dateHandler(department.fromDateTime)}
                />
                <InfoLine
                  header="Til dato:"
                  info={dateHandler(department.toDateTime)}
                />
              </div>
            </div>
            <div className="divider">
              <Divider />
            </div>
            <div>
              <InfoLine
                header="Ansvarsområde"
                info={department.ansvarsomrade}
                vertical={true}
              />
              <InfoLine
                header="Ansvarsområde på engelsk"
                info={<div dangerouslySetInnerHTML={{ __html: department?.ansvarsomradeInEnglish }} />}
                vertical={true}
              />
              <InfoLine
                header="Historisk kommentar"
                info={<div dangerouslySetInnerHTML={{ __html: department.historicalComment }} />}
                vertical={true}
              />
              <InfoLine
                header="Historisk kommentar på engelsk"
                info={<div dangerouslySetInnerHTML={{ __html: department.historicalCommentInEnglish }} />}
                vertical={true}
              />
              <InfoLine
                header="Avviksmerknad"
                info={<div dangerouslySetInnerHTML={{ __html: department.note }} />}
                vertical={true}
              />
              <div className="edit-detail">
                <MinistryForm
                  data={department}
                  refresh={refreshPage}
                  open={open}
                  handleOpenStatus={handleOpenStatus}
                />
                <Button
                  className="rediger-button"
                  onClick={() => handleOpenStatus()}
                >
                  Rediger departement
                </Button>
                <DeleteWarning
                  name="departement"
                  prop="et"
                  onDelete={onDelete}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
      {del && (
        <Snackbar open={openSnack} autoHideDuration={4000}>
          <Alert severity={del.succeeded ? "success" : "error"}>
            {del.succeeded
              ? "Departementet ble slettet"
              : "Departementet ble ikke slettet, prøv igjen"}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
