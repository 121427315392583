import React, { useState } from "react";
import { Container, Navbar, NavItem, NavLink, Row } from "reactstrap";
import { Link } from "react-router-dom";

export function NavMenu() {
  const [displayName, setDisplayName] = useState("");

  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
        color="light"
      >
        <Container>
          <Row className="d-sm-inline-flex flex-sm" navbar>
            <ul className="navbar-nav flex-grow ">
              <NavItem className="topnav">
                <NavLink
                  onClick={() => setDisplayName("Regjeringspolitiker")}
                  tag={Link}
                  to="/regjeringspolitiker"
                  className="navbar-text"
				  activ
                >
                  Regjeringspolitikere
                </NavLink>
              </NavItem>

              <NavItem className="topnav">
                <NavLink
                  tag={Link}
                  onClick={() => setDisplayName("Departement")}
                  to="/departement"
                  className="navbar-text"
                >
                  Departementer
                </NavLink>
              </NavItem>

              <NavItem className="topnav">
                <NavLink
                  tag={Link}
                  onClick={() => setDisplayName("Regjering")}
                  to="/regjering"
                  className="navbar-text"
                >
                  Regjeringer
                </NavLink>
              </NavItem>

              <NavItem className="topnav">
                <NavLink
                  tag={Link}
                  onClick={() => setDisplayName("Partier")}
                  to="/partier"
                  className="navbar-text"
                >
                  Partier
                </NavLink>
              </NavItem>
            </ul>
          </Row>
        </Container>
      </Navbar>
    </header>
  );
}
