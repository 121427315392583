import { fetchDoubleQueryAuth } from "./FetchApiData";

export async function getStatsråds({ setStatsrads, regjeringId }) {
  const query = regjeringId
    ? `regjeringId=${regjeringId}&all=true`
    : "all=true";
  const result = await fetchDoubleQueryAuth("ministers", query).then((res) =>
    res.data.map((opt) => ({
      value: opt.id,
      label: `${opt.statsradTitle}  -  ${opt.person.firstName}  ${
        opt.person.lastName
      } - ${opt.statsradType.type} - ${opt.fromDateTime?.substring(0, 5)} ${
        opt.toDateTime?.substring(0, 4) || ""
      }`,
    }))
  );

  setStatsrads(result);
}
