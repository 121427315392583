
export default function dateHandler(date) {
  if (!date) {
    return "";
  }

  if (date.substring(0, 4) === "9999") {
    return "Aktiv";
  }
  var dateString = formatDateToYMD(new Date(date));

  if (dateString.indexOf("-") > 3) {
    return `${dateString.substring(8, 10)}.${dateString.substring(
      5,
      7
    )}.${dateString.substring(0, 4)}`;
  }
}

export function capslockTextFormat(text) {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }
  return "";
}

export function futureDate(date) {
  if (!date) {
    return null;
  } else if (date.toString().substring(0, 4) === "9999") {
    return null;
  } else {
    return date;
  }
}

function formatDateToYMD(date) {
  var d = date.getDate();
  var m = date.getMonth() + 1; //Month from 0 to 11
  var y = date.getFullYear();
  return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
}
