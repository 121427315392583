import React from "react";
import { Route } from "react-router";
import "./custom.css";
import { Layout } from "./components/Layout.jsx";
import { GovernmentPage } from "./components/government/GovernmentPage";
import { PersonPage } from "./components/person/PersonPage";
import { MinistryPage } from "./components/ministry/MinistryPage";
import { PartyPage } from "./components/parties/PartyPage";
import MinistryDetail from "./components/ministry/MinistryDetail";
import PersonDetail from "./components/person/PersonDetail";
import GovernmentDetail from "./components/government/GovernmentDetail";
import PartyDetail from "./components/parties/PartyDetail";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
const config = require("./appEndpoints.json");
export default function App() {

  return (
    <Layout>
		<PageContetn />
    </Layout>
  );
}
const PageContetn = () => <div className="protected">
	<AuthenticatedTemplate>
		<ProtectedContent />
	</AuthenticatedTemplate>

	<UnauthenticatedTemplate>
		<RedirectToLogin />
	</UnauthenticatedTemplate>
</div>;

const ProtectedContent = () => {
  const { instance, accounts } = useMsal();
  const [methodData, setmethodData] = React.useState();

  React.useEffect(() => {
    RequestProfileData();
  }, []);

  const RequestProfileData = () => {
    // Silently acquires an access token which is then attached to a request
    instance
      .acquireTokenSilent({
        loginRequest: config.msalAPI.loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        setmethodData(content);
      })
	  .catch(error=>setmethodData(<p>Data ble ikke fetched</p>));
  };

  let contents = methodData ? (
    methodData
  ) : (
    <p>
      <em>Loading...</em>
    </p>
  );

  return <>{contents}</>;
};

const RedirectToLogin = () => {
  const { instance } = useMsal();
  instance.loginRedirect(config.msalAPI.loginRequest).catch((e) => {
    debugger;
    console.log(e);
  });
  return <></>;
};

const content = (
  <>
    <Route exact path="/" component={PersonPage} />
    <Route exact path="/departement" component={MinistryPage} />
    <Route path="/departement/:id" component={MinistryDetail} />
    <Route exact path="/regjeringspolitiker" component={PersonPage} />
    <Route path="/regjeringspolitiker/:personId" component={PersonDetail} />
    <Route exact path="/partier" component={PartyPage} />
    <Route path="/partier/:partyId" component={PartyDetail} />
    <Route exact path="/regjering" component={GovernmentPage} />
    <Route path="/regjering/:govId" component={GovernmentDetail} />
    {/* <Redirect exact to="/" /> */}
  </>
);
