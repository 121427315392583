import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { deleteDataAuth, fetchDataElementAuth } from "../../functionality/FetchApiData";
import InfoLine from "../InfoLine";
import Divider from "@material-ui/core/Divider";
import dateHandler from "../../functionality/TextHandler";
import PartyForm from "./PartyForm";
import Loading from "../Loading";
import { useHistory } from "react-router-dom";
import DeleteWarning from "../DeleteWarning";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Button from "reactstrap/lib/Button";

export default function PartyDetail() {
  const { partyId } = useParams();
  const [party, setParty] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [del, setDel] = useState();
  const [open, setOpen] = useState(false);

  const handleOpenStatus = () => {
    setOpen(!open);
  };

  const history = useHistory();
  useEffect(() => {
    getParty();
  }, []);

  async function getParty() {
	  const party = await fetchDataElementAuth("political-parties", partyId)
	  if(party) setParty(party);
	  else history.push("/partier");
  }
  const onDelete = async () => {
    const res = await deleteDataAuth("political-parties", party.id);
    setOpenSnack(true);
    setDel(res);
    if (res.succeeded) {
      setTimeout(() => {
        setOpenSnack(false);
        history.replace(`/partier`);
      }, 3000);
    } else {
      setTimeout(() => setOpenSnack(false), 6000);
    }
  };

  function refreshPage() {
    window.location.reload();
  }
  return (
    <div>
      {party ? (
        <div className="detail-page">
          <Link className="tilbake-btn" tabIndex={0} to="/partier">
            Tilbake
          </Link>
          <h1>{party.partiName}</h1>
          <div className="info-page">
            <div className="main-info">
              <div>
                <InfoLine header="Parti ID: " info={party.id} />
                <InfoLine header="Partikode: " info={party.partiCode} />
                <InfoLine header="Navn:" info={party.partiName} />
                <InfoLine
                  header="Navn på engelsk:"
                  info={party.partiNameInEnglish}
                />
                <InfoLine
                  header="Fra dato:"
                  info={dateHandler(party.fromDateTime)}
                />
                <InfoLine
                  header="Til dato:"
                  info={dateHandler(party.toDateTime)}
                />
                <div className="divider">
                  <Divider />
                </div>
                <div>
                  <InfoLine
                    header="Avviksmerknad"
                    info={party.note}
                    vertical={true}
                  />
                </div>
              </div>
            </div>
            <div className="edit-detail">
              <PartyForm
                data={party}
                refresh={refreshPage}
                open={open}
                handleOpenStatus={handleOpenStatus}
              />
              <Button
                className="rediger-button"
                onClick={() => handleOpenStatus()}
              >
                Rediger parti
              </Button>
              <DeleteWarning name="parti" prop="et" onDelete={onDelete} />
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
      {del && (
        <Snackbar open={openSnack} autoHideDuration={4000}>
          <Alert severity={del.succeeded ? "success" : "error"}>
            {del.succeeded
              ? "Partiet ble slettet"
              : "Partiet ble ikke slettet, prøv igjen"}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
